import React from 'react'
import { graphql , Link } from 'gatsby'
import Layout from '../../components/Layout'
import * as styles from '../../styles/projects.module.css'
import Img from 'gatsby-image'


const Projects = ({ data }) => {
    console.log(data)
    // const projects = data.allMarkdownRemark.nodes
    const projects = data.projects.nodes
    const contact = data.contact.siteMetadata.contact
    

    return (
        <Layout>
            <div className={styles.portfolio} >
                <h2>Portfolio </h2>
                <h3>Projects & Websites I've Created </h3>

                <p> click image below for project details ....</p>
                <div className={styles.projects}>
                    {projects.map(project => (
                        <Link to={"/projects/" + project.frontmatter.slug} key={project.id}>
                            <div>
                                <Img fluid={project.frontmatter.thumb.childImageSharp.fluid} />
                                <h3> {project.frontmatter.title}</h3>
                                <p>  {project.frontmatter.stack}</p>
                            </div>
                        </Link>
                    ))}
                </div>
                <p> Like what you see? Email me at <strong>{ contact }  </strong> for a quote!</p>
            </div>
        </Layout>

    )
}

// export page query
// get only pageType = 'projects'

export const query = graphql`
  query ProjectsPage {
    projects: allMarkdownRemark(
      filter: {frontmatter: {pageType: {eq: "projects"}}}
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      nodes {
        frontmatter {
          title
          stack
          slug
          date
          pageType
          thumb {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
      }
    }
    contact: site {
      siteMetadata {
        contact
      }
    }
  }
` 

  
export default Projects
